export const HOMEPAGE_CONSTANTS = {

    

    // ************************** AMC + InstRepair DATA **************************
    AmcIr : {
        PromotionBannerOneProps: {
            image_path: '/static/images/home/revamp/ir-page/Banner 1/Laptop-Banner-New.webp',
            link: '/laptop-repair-service?nav=homepage_banner1',
            
            slug: '/laptop-repair-service',
            params: 'nav=homepage_banner1',
            isCity: true, 
        },
        PromotionBannerTwoProps: {
            image_path: '/static/images/home/revamp/ir-page/Banner 2/tv-ew.png',
            link: '/televisions-extended-warranty-plans?nav=homepage_banner2'
        },
        PromotionBannerThreeProps: {
            image_path: '/static/images/home/revamp/ew-page/Banner 2/Web Banner_800 X 150.jpg',
            link: '/selfserve?nav=homepage_banner2'
        },
        AcServicesProps: {
            type: 'feature',
            has_underline: true,
            subtitle: '',
            title: 'Air Conditioner Services',
            tag_name: '',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 190,
            mobile_height: 105,
            settings: {
                autoplay: false,
                dots: false,
                infinite: false,
                arrows: true,
                slidesToShow:3,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1.7,
                            arrows:false
                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 1 - Air Conditioner/jet.png',
                    offer: '',
                    title: 'AC Jet service',
                    link: '/ac-service-repair?nav=feature_carousel1',

                    slug: '/ac-service-repair',
                    params: 'nav=feature_carousel1',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 1 - Air Conditioner/gas.png',
                    offer: '',
                    title: 'Gas Refilling',
                    link: '/ac-service-repair?nav=feature_carousel1',

                    slug: '/ac-service-repair',
                    params: 'nav=feature_carousel1',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 1 - Air Conditioner/service.png',
                    offer: '',
                    title: 'Annual Service Plans',
                    link: '/ac-service-repair?nav=feature_carousel1',

                    slug: '/ac-service-repair',
                    params: 'nav=feature_carousel1',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 1 - Air Conditioner/repair.jpg',
                    offer: '',
                    title: 'AC Repair',
                    link: '/ac-service-repair?nav=feature_carousel1',

                    slug: '/ac-service-repair',
                    params: 'nav=feature_carousel1',
                    isCity: true,
                },
            ]

        },
        LargeApplianceRepairProps: {
            type: 'feature',
            has_underline: false,
            subtitle: '',
            title: 'Popular Repair Services',
            tag_name: 'Quick, easy & expert solutions',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 190,
            mobile_height: 105,
            settings: {
                autoplay: false,
                dots: false,
                slidesToShow: 3,                
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1.7,
                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 2 -Popular repairs/wp-repair.png',
                    offer: '',
                    title: 'Water Purifier',
                    link: '/ro-water-purifier-service-repair?nav=feature_carousel2',

                    slug: '/ro-water-purifier-service-repair',
                    params: 'nav=feature_carousel2',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 2 -Popular repairs/laptop-repair.png',
                    offer: '',
                    title: 'Laptop',
                    link: '/laptop-repair-service?nav=feature_carousel2',

                    slug: '/laptop-repair-service',
                    params: 'nav=feature_carousel2',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC 2 -Popular repairs/mobile-repair.png',
                    offer: '',
                    title: 'Mobile',
                    link: '/mobile-phone-repair-service?nav=feature_carousel2',
                },
            ]
        },
        ApplianceRepairUnderpriceProps: {
            type: 'grid',
            has_underline: false,
            subtitle: 'Quick Repairs',
            title: 'Device Repair Starts at &#8377; 199',
            tag_name: 'Hassle-free service at your doorstep',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 160,
            mobile_height: 96,
            settings: {
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots:true

                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            rows:2,
                            dots: true,
                            arrows: false,
                            infinite: true,
                            autoplay: true

                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Water-purifier.webp',
                    offer: '',
                    title: 'Water Purifier',
                    link: '/ro-water-purifier-service-repair?nav=grid_carousel1',
                    slug: '/ro-water-purifier-service-repair',
                    params: 'nav=grid_carousel1',
                    isCity: true,
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Refrigerator.webp',
                    offer: '',
                    title: 'Refrigerator',
                    link: '/refrigerator-repair-service?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Washing-machine.webp',
                    offer: '',
                    title: 'Washing Machine',
                    link: '/washing-machine-repair-service?nav=grid_carousel1',
                },    
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Microwave.webp',
                    offer: '',
                    title: 'Microwave',
                    link: '/microwave-repair-service?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Chimney.webp',
                    offer: '',
                    title: 'Chimney',
                    link: '/chimney-hob-repair-service?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Geyser.webp',
                    offer: '',
                    title: 'Geyser',
                    link: '/geyser-repair-service?nav=grid_carousel1',
                },   
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Printer-scanner.webp',
                    offer: '',
                    title: 'Printer',
                    link: '/printer-repair-service?nav=grid_carousel1',
                }         
            ]
        },
        CompleteMobileProtectionProps: {
            type: 'feature',
            has_underline: false,
            subtitle: '',
            title: 'Complete Mobile Protection',
            tag_name: 'Protection from spills, drops & defects',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 190,
            mobile_height: 105,
            settings: {
                autoplay: false,
                dots: false,
                slidesToShow: 3,
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1.7,
                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/ir-page/FC-3 Mobile Protection/mobile-sdp.webp',
                    offer: '',
                    title: 'Damage Protection Plan',
                    link: '/mobile-phones-damage-protection-plans?nav=feature_carousel3',
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC-3 Mobile Protection/mobile-sp.webp',
                    offer: '',
                    title: 'Screen Protection Plan',
                    link: '/mobile-phones-screen-protection-plans?nav=feature_carousel3',
                },
                {
                    image_path: '/static/images/home/revamp/ir-page/FC-3 Mobile Protection/mobile-ew.webp',
                    offer: '',
                    title: 'Extended Warranty Plan',
                    link: '/mobile-phones-extended-warranty-plans?nav=feature_carousel3',
                },
            ]
        },
        ProtectionPlansStartingAtPriceProps: {
            type: 'grid',
            has_underline: false,
            subtitle: 'Protection Plans',
            title: 'Extend the Life of Your Devices',
            tag_name: 'Protection plans for devices & appliances',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 160,
            mobile_height: 96,
            settings: {
                autoplay: false,
                autoplaySpeed: 3000,
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            rows:2,
                            dots: true,
                            arrows: false,
                            infinite: true,
                            autoplay: true
                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Laptop.webp',
                    offer: '',
                    title: 'Laptop',
                    link: '/laptops-extended-warranty-plans?nav=grid_carousel2',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Mobile-phone.webp',
                    offer: '',
                    title: 'Mobile',
                    link: '/mobile-phones-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Television.webp',
                    offer: '',
                    title: 'Television',
                    link: '/televisions-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Washing-machine.webp',
                    offer: '',
                    title: 'Washing Machine',
                    link: '/washing-machines-extended-warranty-plans?nav=grid_carousel2',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Refrigerator.webp',
                    offer: '',
                    title: 'Refrigerator',
                    link: '/refrigerators-extended-warranty-plans?nav=grid_carousel2',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Fitness-tracker.webp',
                    offer: '',
                    title: 'Fitness Tracker',
                    link: '/fitness-trackers-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Air-purifier.webp',
                    offer: '',
                    title: 'Air Purifier',
                    link: '/air-purifiers-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Chopper-blender.webp',
                    offer: '',
                    title: 'Chopper & Blender',
                    link: '/choppers-blenders-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Digital-camera.webp',
                    offer: '',
                    title: 'Digital Camera',
                    link: '/digital-cameras-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Dishwasher.webp',
                    offer: '',
                    title: 'Dishwasher',
                    link: '/dishwashers-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Geyser.webp',
                    offer: '',
                    title: 'Geyser',
                    link: '/geysers-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Gaming-console.webp',
                    offer: '',
                    title: 'Gaming Console',
                    link: '/gaming-consoles-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Home-theatre-soundbar.webp',
                    offer: '',
                    title: 'Home Theater & Soundbar',
                    link: '/home-theatres-soundbars-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Microwave.webp',
                    offer: '',
                    title: 'Microwave',
                    link: '/microwave-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Printer-scanner.webp',
                    offer: '',
                    title: 'Printer',
                    link: '/printers-scanners-extended-warranty-plans?nav=grid_carousel2',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Tablet.webp',
                    offer: '',
                    title: 'Tablet',
                    link: '/tablets-extended-warranty-plans?nav=grid_carousel2',
                },
            ]
        },
        HeroProductProps: {
            cta_link: '/home-repair-maintenance-plans?nav=hero_product',
            subtitle: 'Your Complete Device Protection Partner',
            title: 'HomeCare Plan',
            image_path: '/static/images/home/revamp/ir-page/Hero banner - Homecare /hc.png'
        }
    },

    // ************************** Protection Plans Data **************************

    ProtectionPlans : {
        PromotionBannerOneProps: {
            image_path: '/static/images/home/revamp/ew-page/Banner 1/tv-ew.png',
            link: '/televisions-extended-warranty-plans?nav=homepage_banner1'
        },
        PromotionBannerTwoProps: {
            image_path: '/static/images/home/revamp/ew-page/Banner 2/Web Banner_800 X 150.jpg',
            link: '/selfserve?nav=homepage_banner2'
        },
        CompleteMobileProtectionProps: {
            type: 'feature',
            has_underline: false,
            subtitle: '',
            title: 'Complete Mobile Protection',
            tag_name: 'Protection from spills, drops & defects',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 190,
            mobile_height: 105,
            settings: {
                autoplay: false,
                dots: false,
                slidesToShow: 3,
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1.7,
                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/ew-page/FC-Mobile Protection/mobile-sdp.webp',
                    offer: '',
                    title: 'Damage Protection Plan',
                    link: '/mobile-phones-damage-protection-plans?nav=feature_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/ew-page/FC-Mobile Protection/mobile-sp.webp',
                    offer: '',
                    title: 'Screen Protection Plan',
                    link: '/mobile-phones-screen-protection-plans?nav=feature_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/ew-page/FC-Mobile Protection/mobile-ew.webp',
                    offer: '',
                    title: 'Extended Warranty Plan',
                    link: '/mobile-phones-extended-warranty-plans?nav=feature_carousel1',
                },
            ]
        },
        ProtectionPlansStartingAtPriceProps: {
            type: 'grid',
            has_underline: false,
            subtitle: 'Protection Plans',
            title: 'Extend the Life of Your Devices',
            tag_name: 'Protection plans for devices & appliances',
            has_cap: true,
            desktop_width: 380,
            desktop_height: 210,
            mobile_width: 160,
            mobile_height: 96,
            settings: {
                autoplay: false,
                autoplaySpeed: 3000,
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            dots:true
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: true,
                            rows: 2,
                            infinite: true,
                            arrows: false,
                            autoplay: true

                        }
                    }
                ]
            },
            items: [
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Laptop.webp',
                    offer: '',
                    title: 'Laptop',
                    link: '/laptops-extended-warranty-plans?nav=grid_carousel1',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Mobile-phone.webp',
                    offer: '',
                    title: 'Mobile',
                    link: '/mobile-phones-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Television.webp',
                    offer: '',
                    title: 'Television',
                    link: '/televisions-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Washing-machine.webp',
                    offer: '',
                    title: 'Washing Machine',
                    link: '/washing-machines-extended-warranty-plans?nav=grid_carousel1',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Refrigerator.webp',
                    offer: '',
                    title: 'Refrigerator',
                    link: '/refrigerators-extended-warranty-plans?nav=grid_carousel1',
                },

                {
                    image_path: '/static/images/home/revamp/carousal/grid/Fitness-tracker.webp',
                    offer: '',
                    title: 'Fitness Tracker',
                    link: '/fitness-trackers-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Air-purifier.webp',
                    offer: '',
                    title: 'Air Purifier',
                    link: '/air-purifiers-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Chopper-blender.webp',
                    offer: '',
                    title: 'Chopper & Blender',
                    link: '/choppers-blenders-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Digital-camera.webp',
                    offer: '',
                    title: 'Digital Camera',
                    link: '/digital-cameras-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Dishwasher.webp',
                    offer: '',
                    title: 'Dishwasher',
                    link: '/dishwashers-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Geyser.webp',
                    offer: '',
                    title: 'Geyser',
                    link: '/geysers-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Gaming-console.webp',
                    offer: '',
                    title: 'Gaming Console',
                    link: '/gaming-consoles-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Home-theatre-soundbar.webp',
                    offer: '',
                    title: 'Home Theater & Soundbar',
                    link: '/home-theatres-soundbars-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Microwave.webp',
                    offer: '',
                    title: 'Microwave',
                    link: '/microwave-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Printer-scanner.webp',
                    offer: '',
                    title: 'Printer',
                    link: '/printers-scanners-extended-warranty-plans?nav=grid_carousel1',
                },
                {
                    image_path: '/static/images/home/revamp/carousal/grid/Tablet.webp',
                    offer: '',
                    title: 'Tablet',
                    link: '/tablets-extended-warranty-plans?nav=grid_carousel1',
                },
            ]
        },
        HeroProductProps: {
            cta_link: '/mobile-phones-extended-warranty-plans?nav=hero_product',
            subtitle: 'Mobile Phone Protection',
            title: 'Use your phone without worry',
            image_path: '/static/images/home/revamp/ew-page/Hero Product/ew.png'
        }
    }
}